import React, { useContext } from "react"
import { Image } from "../../../atoms/Image"
import { StaticCarouselItemProps } from "./HeroCarousel.d"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import { css } from "@emotion/react"
import { ButtonLink } from "../../../atoms/Button"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../../helpers"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { AnimatePresence, motion } from "framer-motion"

const StaticCarouselItem: React.FC<StaticCarouselItemProps> = ({
  bgImage,
  bgImageMobile,
  boxColor,
  boxColorMobile,
  textColor,
  logoImage,
  logoImageMobile,
  heading,
  subheading,
  ctaArray,
  disclaimers,
  index,
}) => {
  const { language, _ } = useContext(LanguageContext)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <>
      <section
        css={[
          tw`flex flex-col h-auto relative`,
          tw`lg:(items-center flex-row h-[600px])`,
        ]}
      >
        {/* disclaimers */}
        {disclaimers && (
          <div css={[tw`absolute top-[340px] right-10 z-30`, tw`lg:(hidden)`]}>
            {parseDisclaimerBlocks(disclaimers, selection =>
              modalDispatch(toggleDisclaimersModal(selection))
            )}
          </div>
        )}
        <div css={tw`relative md:(static)`}>
          {bgImage && (
            <>
              <Image
                imageData={bgImage?.image}
                css={[
                  tw`relative hidden w-full h-[380px] object-cover object-[93% 50%]`,
                  `@media screen and (min-width: 390px) {
                object-position: 100% 100%;
                height:400px;
              }`,
                  tw`md:(h-[380px] object-[100% 100%])`,
                  tw`lg:(h-[600px] block absolute object-[66% 50%] top-0)`,
                  "z-index: 0;",
                ]}
              />
              <Image
                imageData={bgImageMobile?.image}
                css={[
                  tw`relative w-full h-[380px] object-cover object-[93% 50%]`,
                  `@media screen and (min-width: 390px) {
                object-position: 100% 100%;
                height:400px;
              }`,
                  tw`md:(h-[380px] object-[100% 100%])`,
                  tw`lg:(h-[600px] hidden absolute object-[66% 50%] top-0)`,
                  "z-index: 0;",
                ]}
              />
            </>
          )}
        </div>
        {/* Left box with logo */}
        <div
          css={[
            tw`relative z-20 h-full w-full max-w-[1440px] mx-auto`,
            ctaArray?.length === 0 && tw`pb-[72px] md:(pb-0)`,
          ]}
        >
          <div
            css={[
              tw`px-6 pt-4 pb-4 mb-[6.5rem] transition delay-300 duration-300 ease-in-out w-full z-10`,
              tw`md:(pb-10)`,
              tw`lg:(absolute top-[46%] transform -translate-y-1/2 left-10 w-auto bg-opacity-90 px-8 pb-4 pt-6 my-auto rounded-2xl backdrop-blur-sm)`,
              tw`xl:(pb-4 left-[6rem] top-[43%])`,
              `backdrop-filter: blur(1px);`,
              `background:${boxColor ? `${boxColor.hex}D9` : `#1E262ED9`}`,
              `@media (max-width: 1024px) {
           background:${boxColor ? `${boxColor.hex}D9` : `#1E262ED9`}`,
            ]}
          >
            <div>
              <div
                css={[
                  tw`flex flex-col items-center justify-center text-center mx-auto w-full mt-4 lg:(mt-0 justify-start w-auto)`,
                ]}
              >
                {heading && (
                  <div
                    css={[
                      tw`text-3xl font-semibold`,
                      css`
                        color: ${textColor?.hex ? textColor?.hex : "#EEE"};
                      `,
                    ]}
                  >
                    {heading}
                  </div>
                )}
                {subheading && (
                  <div
                    css={[
                      tw`text-lg px-8`,
                      css`
                        color: ${textColor?.hex ? textColor?.hex : "#EEE"};
                      `,
                    ]}
                  >
                    {subheading}
                  </div>
                )}
                <Image
                  imageData={logoImage?.image}
                  objectFit="cover"
                  transparentPreview
                  hideAlt
                  aria-label={logoImage?.image?.asset?.altText}
                  css={[
                    tw`hidden w-full mt-6 min-w-[300px] md:(w-[400px]) lg:(block w-[375px])`,
                  ]}
                />
                <Image
                  imageData={logoImageMobile?.image}
                  objectFit="cover"
                  transparentPreview
                  hideAlt
                  aria-label={logoImageMobile?.image?.asset?.altText}
                  css={[
                    tw`w-full mt-6 min-w-[300px] md:(w-[400px]) lg:(hidden w-[375px])`,
                  ]}
                />
              </div>
            </div>
            {/* CTAs */}
            {ctaArray && (
              <AnimatePresence>
                <motion.div
                  css={[
                    tw`mt-6 flex grid-cols-2 gap-2 justify-center lg:justify-start`,
                  ]}
                  key={index}
                  animate={{ opacity: 1, x: 0 }}
                  initial={{ opacity: 0, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                >
                  {ctaArray?.map((cta: any) => (
                    <ButtonLink
                      {...{ [cta?.buttonType]: true }}
                      to={getCTALinkValue(cta)}
                      target={cta?.linkType === "external" ? "_blank" : "_self"}
                      css={[tw`whitespace-nowrap`, tw`lg:w-auto`]}
                      aria-label={cta?.title}
                      key={cta?.title}
                      analytics-id={`${cta?.title}:homepage carousel:${index}`}
                      onClick={() => {
                        trackTealEvent({
                          tealium_event: "cta_click",
                          coupon_module_text: cta?.title,
                          link_href: `${getCTALinkValue(cta)}`,
                        })
                      }}
                    >
                      {cta?.title}
                    </ButtonLink>
                  ))}
                </motion.div>
              </AnimatePresence>
            )}
          </div>
        </div>
      </section>
      {/* disclaimers */}
      {disclaimers && (
        <div
          css={[
            tw`hidden`,
            tw`lg:(block absolute text-xl bottom-10 right-10 z-30)`,
          ]}
        >
          {parseDisclaimerBlocks(disclaimers, selection =>
            modalDispatch(toggleDisclaimersModal(selection))
          )}
        </div>
      )}
    </>
  )
}

export default StaticCarouselItem
