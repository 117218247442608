import React from "react"
import { useState, useEffect, useContext } from "react"
import { Offer } from "../../../../global"
import OfferInfo from "./OfferInfo"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import {
  setDisclaimers,
  toggleDisclaimersModal,
} from "../../../../contexts/Disclaimers/actions"
import { Disclaimer } from "../../../../contexts/Disclaimers/disclaimers.d"
import { LanguageContext } from "../../../../contexts/Language"
import _ from "lodash"
import { motion } from "framer-motion"
import tw from "twin.macro"
import { parseDisclaimerBlocks, reformatCategory } from "../../../../helpers"
import ToyotaCareLogo from "./ToyotCareLogo"
import ToyotaCareLogoLight from "./ToyotCareLogoLight"
import { Button } from "../../../atoms/Button"
import {
  removeFavoritedOffer,
  addFavoritedOffer,
  setFavoriteMessage,
} from "../../../../contexts/Favorites/actions"
import Icon from "../../../atoms/Icon"
import CardBg from "./CardBg"
import { FavoritesContext } from "../../../../contexts/Favorites/context"
import { FeaturedOffersProps } from "./Hero.d"
import { Link } from "../../../atoms/Link"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../../contexts/Tealium"
import { DealerDetailsPageContext } from "../../../../templates/dealerDetails"
import useVehicleData from "../../../../hooks/Tealium/useVehicleData"
import { SeriesPageContext } from "../../../../templates/series"

const FeaturedOffers: React.FC<FeaturedOffersProps> = ({
  offers,
  salesEvent,
  textColor,
  chevronColor = "white",
  ...remainingProps
}) => {
  const [{ disclaimers }, dispatch] = useContext(DisclaimersContext)
  const language = useContext(LanguageContext)
  const [{ favoritedOffers }, dispatchFavorited] = useContext(FavoritesContext)

  const [selectedOffer, setSelectedOffer] = useState(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [selectedOfferIsFavorited, setSelectedOfferIsFavorited] =
    useState(false)
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const { dealer } = useContext(DealerDetailsPageContext)
  const [vehicleData] = useVehicleData()
  const { vehicle } = useContext(SeriesPageContext)
  // Spanish text is longer, so we need to adjust the styles
  const useEsWideOfferStyles = language.language === "es" && offers.length > 1

  const reformattedCategory = reformatCategory(vehicle?.series?.category)
  // Add all offer disclaimers to disclaimers context
  useEffect(() => {
    if (
      !Array.isArray(disclaimers) ||
      (!Array.isArray(offers) && offers.length === 0)
    )
      return

    const offerDisclaimers = offers
      .map((offer: Offer) => {
        const disclaimerAlreadyIncluded = disclaimers?.some(
          (disclaimer: Disclaimer) => disclaimer.code === offer?._id
        )
        if (disclaimerAlreadyIncluded) return null
        return {
          code: offer._id,
          disclaimer:
            language.language === "es"
              ? offer?.disclaimerES
              : offer?.disclaimer,
          disclaimer_en: offer.disclaimer,
          disclaimer_es: offer.disclaimerES,
          extraDisclaimer:
            language.language === "es"
              ? offer?.extraDisclaimerES
              : offer?.extraDisclaimer,
          active: false,
        }
      })
      ?.filter((disclaimer: Disclaimer) => disclaimer !== null)

    if (offerDisclaimers.length > 0) {
      const newDisclaimers = _.unionBy(offerDisclaimers, disclaimers, "code")
      dispatch(setDisclaimers(newDisclaimers))
    }
  }, [disclaimers])

  // Setting selected offer to first offer in array
  useEffect(() => {
    if (Array.isArray(offers) && offers.length > 0) {
      setSelectedOffer(offers[0])
    }
  }, [offers])

  // Update selected offer favorited state
  useEffect(() => {
    if (!favoritedOffers || !Array.isArray(favoritedOffers)) return

    setSelectedOfferIsFavorited(favoritedOffers.includes(selectedOffer?._id))
  }, [favoritedOffers, selectedOffer])

  const cardTitle = `${vehicle?.year} ${vehicle?.series?.name} ${selectedOffer?.type}`
  // If no offers, show ToyotaCare
  if (offers.length === 0) {
    return (
      <motion.section
        {...remainingProps}
        css={[
          tw`flex flex-row items-center justify-center gap-x-10 !mb-0 relative `,
          tw`md:(gap-10 flex-row mx-0)`,
          tw`text-xl`,
        ]}
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 10 }}
        transition={{ duration: 1, delay: 1.2, type: "spring" }}
      >
        <article
          css={[
            tw`flex flex-col justify-center items-center gap-y-3 text-white`,
            salesEvent && tw`text-black`,
            tw`lg:(text-black)`,
            language.language === "en" && tw`text-white lg:(text-white)`,
          ]}
          {...remainingProps}
        >
          {language._("2 Years | 25,000 Miles")}
          <div css={[tw`flex flex-col my-5`]}>
            <div>
              {/* display ToyotaLogoLight on mobile devices when salesEvent is true and display ToyotaCareLogo for desktop */}

              {salesEvent ? (
                <>
                  {/* <ToyotaCareLogoLight
                    css={[tw`inline-block mr-1 lg:hidden`]}
                  /> */}
                  {language.language === "en" ? (
                    <ToyotaCareLogoLight
                      classic={true}
                      css={[tw`inline-block mr-1`]}
                    />
                  ) : (
                    // Spanish
                    <ToyotaCareLogo
                      classic={true}
                      css={[tw`inline-block mr-1`]}
                    />
                  )}
                </>
              ) : (
                <ToyotaCareLogoLight css={[tw`inline-block mr-1 w-[150px]`]} />
              )}

              {parseDisclaimerBlocks(`[toyota_care]`, selection =>
                dispatch(toggleDisclaimersModal(selection))
              )}
            </div>
            <div css={[tw`px-8 normal-case pt-2`, tw`lg:(px-24)`]}>
              {language._("Included with every new Toyota lease or purchase.")}
            </div>
          </div>
        </article>
      </motion.section>
    )
  }

  return (
    <div>
      <div
        css={[
          tw`mx-auto w-full max-w-[calc(100vw - 2rem)] sm:(max-w-[calc(100vw / 1.5 - 2rem)]) md:(max-w-[calc(100vw / 2 - 2rem)] w-[fit-content])`,
        ]}
      >
        {/* Multiple Offer Tabs */}
        {offers.length > 1 && (
          <motion.div
            css={[
              tw`px-16 relative z-20`,
              useEsWideOfferStyles && tw`px-8 max-w-[430px] sm:(px-10)`,
            ]}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 10 }}
            transition={{ duration: 1, delay: 1.2, type: "spring" }}
          >
            <div
              css={[
                tw`grid grid-cols-2 justify-center items-center bg-white w-max mx-auto rounded-full border-2 border-black max-w-full`,
              ]}
            >
              {offers.map((offer: Offer, index: number) => {
                let label = ""
                if (offer.type === "APR") {
                  label = language._("Finance Offer")
                } else if (offer.type === "Lease") {
                  label = language._("Lease Offer")
                }

                return (
                  <Button
                    css={[
                      tw`text-gray-600 shadow-none border-none py-2 px-3 normal-case`,
                      `@media screen and (min-width: 370px) and (max-width: 400px) {
                        font-size:.75rem;
                      }
                      `,
                      tw`sm:(px-6)`,
                      index === selectedTabIndex && tw`text-white bg-black`,
                      index === selectedTabIndex &&
                        salesEvent &&
                        tw`bg-red-400`,
                    ]}
                    onClick={() => {
                      setSelectedOffer(offer)
                      setSelectedTabIndex(index)
                    }}
                  >
                    {label}
                  </Button>
                )
              })}
            </div>
          </motion.div>
        )}
        {/* Single Offer Title */}
        {offers.length === 1 && (
          <motion.div
            css={[
              tw`relative mx-auto tracking-widest text-center text-gray-50`,
              salesEvent && language.language === "en"
                ? tw`text-white`
                : tw`text-black`,
              language.language === "en"
                ? tw`text-lg lg:(text-xl)`
                : tw`text-base lg:(text-lg)`,
            ]}
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 10 }}
            transition={{ duration: 1, delay: 1.2, type: "spring" }}
          >
            {language._("Featured Offer")}
          </motion.div>
        )}
        {/* Selected Offer */}
        <div
          css={[
            tw`relative -mt-2`,
            useEsWideOfferStyles && tw`-mt-3 sm:(-mt-2) md:(-mt-3)`,
          ]}
        >
          <motion.section
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 10 }}
            transition={{ duration: 1, delay: 1.2, type: "spring" }}
          >
            {/* Favorite Button */}
            <button
              onClick={() => {
                selectedOfferIsFavorited
                  ? dispatchFavorited(removeFavoritedOffer(selectedOffer._id))
                  : dispatchFavorited(addFavoritedOffer(selectedOffer._id)),
                  dispatchFavorited(setFavoriteMessage("offers"))
              }}
              css={[
                tw`absolute flex items-center p-1 border border-transparent top-2 right-4 z-10 rounded-full hover:bg-gray-100`,
                tw`sm:(right-6)`,
                tw`md:(top-3)`,
                tw`lg:(top-2)`,
                useEsWideOfferStyles &&
                  tw`right-1 top-3 sm:(right-1.5) md:(right-1.5 top-2) lg:(top-2)`,
              ]}
              aria-label={
                selectedOfferIsFavorited
                  ? `Unfavorite ${
                      language.language === "es"
                        ? selectedOffer?.cardTitleES
                        : selectedOffer?.cardTitle
                    } Offer`
                  : `Favorite ${
                      language.language === "es"
                        ? selectedOffer?.cardTitleES
                        : selectedOffer?.cardTitle
                    } Offer`
              }
            >
              <Icon.Heart
                color={
                  salesEvent && language.language === "en" ? "#EB0A1E" : "white"
                }
                css={[
                  tw`w-6 mt-0.5 ml-auto`,
                  salesEvent && tw`hover:(fill-[#EB0A1E])`,
                ]}
                filled={selectedOfferIsFavorited}
              />
            </button>
            <div css={[tw`grid`]}>
              <CardBg
                css={[
                  tw`relative w-full h-full mx-auto max-w-[465px] z-0`,
                  useEsWideOfferStyles && tw`max-w-[600px] max-h-[180px]`,
                  "grid-column: 1; grid-row: 1;",
                ]}
                color={salesEvent}
                wideTitle={useEsWideOfferStyles}
              />
              {/* Selected Offer Details */}
              <OfferInfo
                offer={selectedOffer}
                salesEventOfferBg={salesEvent}
                textColor={textColor}
                {...remainingProps}
                css={["grid-column: 1; grid-row: 1;"]}
              />
            </div>
          </motion.section>
        </div>
      </div>
      <motion.div
        css={[
          tw`hidden mt-6 sm:(mt-8 mb-10) md:(mt-6 mb-6) lg:(block mt-4 mb-4)`,
        ]}
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 10 }}
        transition={{ duration: 1, delay: 1.2, type: "spring" }}
      >
        <Link
          animated
          css={[
            tw`normal-case text-base cursor-pointer lg:(text-xl)`,
            salesEvent && language.language === "en"
              ? tw`text-white`
              : tw`text-black`,
          ]}
          to={`/offers/${selectedOffer?._id}`}
          onClick={() => {
            // Conditionally add values if they are available
            const tealOptionalValues: {
              dealer_name?: string
              dealer_code?: string
              vehicle_model_truncated?: string
              vehicle_model?: string
              vehicle_year?: string | number
            } = {
              ...(dealer?.Name && { dealer_name: dealer.Name }),
              ...(dealer?.DealerCode && {
                dealer_code: dealer.DealerCode,
              }),
            }
            trackTealEvent({
              ...tealOptionalValues,
              offer_type: `${tealPageData.page_type} | ${tealPageData.page_name} | ${selectedOffer?.type} | ${cardTitle}`,
              tealium_event: "view_offer",
              vehicle_year: vehicle?.year,
              vehicle_segment_vehicle_page: reformattedCategory,
              vehicle_model_truncated: vehicle?.series?.name,
              vehicle_model: vehicle?.series?.name,
            })
          }}
          analytics-id="offer hero:offers"
        >
          {language._("View Offer Details")}{" "}
          <Icon.Chevron
            direction="right"
            color={chevronColor}
            css={[tw`h-2.5 inline lg:(h-3)`]}
          />
        </Link>
      </motion.div>
    </div>
  )
}

export default FeaturedOffers
