import React, { useContext, useEffect } from "react"
import { SeriesSalesEventHeroProps } from "./SeriesSalesEventHero.d"
import tw from "twin.macro"
import { ButtonLink } from "../../../atoms/Button"
import { Image } from "../../../atoms/Image"
import OfferInfo from "../Hero/OfferInfo"
import { CTA, Offer } from "../../../../global"
import { SeriesPageContext } from "../../../../templates/series"
import { motion } from "framer-motion"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { Link } from "../../../atoms/Link"
import Icon from "../../../atoms/Icon"
import { LanguageContext } from "../../../../contexts/Language"
import useOffersData from "../../../../hooks/useOffersData"
import lottieJson from "../../../../animations/AugustEventSeries.json"
import lottieMobileJson from "../../../../animations/AugustEventSeriesMobile.json"
import Lottie from "react-lottie-player"

import {
  generateInventoryLink,
  getCTALinkValue,
  reformatCategory,
  vehicleCategory,
} from "../../../../helpers"
import { useTealiumContext } from "../../../../contexts/Tealium"
import { DealerDetailsPageContext } from "../../../../templates/dealerDetails"
import useVehicleData from "../../../../hooks/Tealium/useVehicleData"
import FeaturedOffers from "../Hero/FeaturedOffers"
import { css } from "@emotion/react"
import { lang } from "moment"
import { OffersClient } from "../../../../clients/OffersClient"

/**
 *
 * @author Cody & Scott
 * @summary
 *
 */

const SeriesSalesEventHero: React.FC<SeriesSalesEventHeroProps> = ({
  model,
  offerType,
  secondaryOfferType,
  heroImage,
  cta,
  _key,
  sectionSlug,
  relatedSeriesPage,
  salesEvent,
}) => {
  const { vehicle } = useContext(SeriesPageContext)
  const offers = useOffersData()
  const cardTitle = `${vehicle?.year} ${vehicle?.series?.name} ${offerType}`
  const { _, language } = useContext(LanguageContext)
  const formattedEndDate = salesEvent?.formattedEndDate
  const { dealer } = useContext(DealerDetailsPageContext)
  const [vehicleData] = useVehicleData()
  const { tealPageData } = useTealiumContext()
  const bgImage = salesEvent?.seriesEventImageBackground
  const bgImageMobile = salesEvent?.mobileSeriesEventImageBackground

  // Image data and alts
  const bgImageMobileData = bgImageMobile?.image
  const bgImageData = bgImage?.image
  const bgImageAlt = bgImage?.alt
  const heroImageData = heroImage?.image
  const heroImageAlt = heroImage?.alt
  const eventVideoPoster = salesEvent?.eventVideoPoster
  const eventVideoMobile = salesEvent?.eventVideoMobile
  const eventVideoPosterMobile = salesEvent?.eventVideoPosterMobile
  const eventForefrontImage = salesEvent?.eventForefrontImage
  const eventImageSeries = salesEvent?.eventImage
  const bgMobileBlurred = salesEvent?.mobileSeriesEventImageBackground?.image

  // Vehicle data
  const seriesName = model?.series?.name
  const vehicleYear = vehicle?.year

  const [featuredOffers, setFeaturedOffers] = React.useState<Offer[]>([])
  useEffect(() => {
    const getOffersData = async () => {
      try {
        const offersData = await OffersClient.getManyOffers({
          model: JSON.stringify(model?.name),
          limit: 2,
          series: JSON.stringify(seriesName),
          type: JSON.stringify([offerType, secondaryOfferType]),
          year: model?.year,
        })
        if (offersData.offers) {
          setFeaturedOffers(prevOffers => [...prevOffers, ...offersData.offers])
        }
      } catch (error) {
        console.error("Error fetching offers data", error)
      }
    }
    getOffersData()
  }, [vehicle, model])
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()
  const reformattedCategory = reformatCategory(vehicle?.series?.category)

  // const handleTealEvent = (title = "") => {
  //   let tealiumEvent = ""
  //   // Currently: Only fire this event if the button is for inventory
  //   let inv = title.toLowerCase().includes("inventory")
  //   if (inv) {
  //     tealiumEvent = "view_inventory_click"
  //     trackTealEvent({
  //       tealium_event: tealiumEvent,
  //       vehicle_model_truncated: seriesName,
  //       vehicle_segment_vehicle_page:
  //         vehicleTealData.vehicle_segment_vehicle_page,
  //       vehicle_year: vehicleYear,
  //       vehicle_model: seriesName,
  //       link_href: "NEEDS VALUE",
  //       coupon_module_text: title,
  //     })
  //   } else {
  //     return
  //   }
  // }

  const getCTALink = (cta: CTA) => {
    let link = getCTALinkValue(cta)
    if (link?.includes("inventory")) {
      link = generateInventoryLink({ series: vehicle?.series?.slug })
    }

    return link
  }

  // START: Logic for Optimizely Test 7
  const orderedCTASforOptimizelyTest7 = cta?.sort((a, b) => {
    const titleA = a.title.toLowerCase()
    const titleB = b.title.toLowerCase()

    if (titleA === "view inventory" || titleA === "view inventario") {
      return -1
    } else {
      return 1
    }
  })
  // END: Logic for Optimizely Test 7

  return (
    <section
      css={[
        tw`relative flex z-50`,
        tw`lg:(relative grid grid-cols-1 grid-rows-1 h-full max-h-[700px])`,
        tw`2xl:(max-h-[800px])`,
      ]}
      key={_key}
      id={sectionSlug?.current}
      aria-label={`${vehicleYear} ${seriesName}` + " Hero Section"}
    >
      {/* <Image
        imageData={salesEvent.seriesEventImageMobile?.image}
        //alt={"Sales Event"}
        //objectFit="cover"
        transparentPreview
        alt={salesEvent.eventImage?.alt}
        css={[tw`absolute z-10 top-0 ml-8 mt-8 w-[200px] lg:(hidden)`]}
      /> */}
      {language === "es" && (
        <div
          css={[
            tw`!normal-case mt-2.5 font-semibold text-lg z-10 absolute top-[230px] w-full text-center p-8`,
            tw`md:(top-96)`,
            tw`lg:(hidden)`,
            css`
              line-height: 1.2;
            `,
            `color: ${salesEvent?.endDateColor?.hex};`,
            `@media screen and (min-width: 370px) {
                    top: 210px;
                  }
                  `,
            `@media screen and (min-width: 420px) {
                    top: 230px;
                  }
                  `,
            `@media screen and (min-width: 760px) {
                    top: 360px;
                  }
                  `,
          ]}
        >
          Evento termina el <br />3 de Septiembre
        </div>
      )}
      <div
        css={[
          tw`hidden`,
          tw`lg:(flex col-span-full absolute self-start h-full w-full auto-rows-min top-0 max-h-[700px])`,
          tw`xl:(max-h-[700px])`,
          tw`2xl:(max-h-[800px])`,
        ]}
      >
        {language === "en" && (
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              objectFit: "cover",
            }}
            rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
          />
        )}
        {language === "es" && (
          <Image
            imageData={bgImageData}
            transparentPreview
            alt={bgImageAlt}
            objectFit="cover"
            css={[tw`object-cover w-auto desktop-hd:(w-full)`]}
          />
        )}
      </div>

      {/* BACKGROUND IMAGE MOBILE */}
      <div
        css={[
          tw`w-full min-h-[356px] h-[356px] absolute top-0 right-0 left-0`,
          language === "es" && tw`min-h-[450px] h-[450px] md:(h-[500px])`,
          tw`md:(h-auto)`,
          tw`lg:(hidden)`,
          // iPadPortraitBackground,
        ]}
      >
        {language === "en" && (
          <Lottie
            loop
            animationData={lottieMobileJson}
            play
            style={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              objectFit: "cover",
            }}
            rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
          />
        )}
        {language === "es" && (
          <Image
            imageData={bgImageMobileData}
            transparentPreview
            alt={bgImageAlt}
            objectFit="cover"
            css={[tw`object-cover h-full`]}
          />
        )}
      </div>

      <div
        css={[tw` relative w-full`, tw`lg:(mt-0 grid grid-rows-1 grid-cols-2)`]}
      >
        {heroImage && (
          <>
            {/* MOBILE HERO IMAGE LOGO AND CAR */}
            <div
              css={[
                tw` w-full h-[46vh] z-20 relative`,
                tw`sm:(h-[42vh])`,
                tw`md:(h-[45vh])`,
                tw`landscape:(min-h-[95vh])`,
                tw`lg:(hidden)`,
                language === "en" && tw`h-[340px] sm:(h-[70vh]) md:(h-[45vh])`,
              ]}
            >
              <div css={[tw`flex mt-4 w-full justify-center`]}>
                <Image
                  imageData={salesEvent?.seriesEventImageMobile?.image}
                  //alt={"Sales Event"}
                  //objectFit="cover"
                  transparentPreview
                  alt={salesEvent?.eventImage?.alt}
                  css={[tw`w-[165px] sm:(w-[240px]) lg:(hidden)`]}
                />
              </div>
              <motion.section
                animate={{ opacity: 1, x: 0, scale: 1 }}
                initial={{ opacity: 0, x: 0, scale: 0.95 }}
                transition={{ duration: 1, delay: 1 }}
                css={[
                  tw`w-full flex items-end justify-center px-7 h-[180px]`,
                  tw`md:(h-[52vh])`,
                  tw`landscape:(h-[66vh])`,
                  `@media screen and (min-width: 370px) {
                    height: 180px;
                  }
                  `,
                  `@media screen and (min-width: 390px) {
                    height: 200px;
                  }
                  `,
                  `@media screen and (min-width: 420px) {
                    height: 225px;
                  }
                  `,
                  `@media screen and (min-width: 760px) {
                    height: 280px;
                  }
                  `,
                  language === "es" && tw`md:(h-[350px] -mt-8)`,
                ]}
              >
                {/* MOBILE VEHICLE */}
                <Image
                  imageData={heroImageData}
                  alt={heroImageAlt}
                  objectFit="cover"
                  css={[
                    tw`w-auto mt-0 z-10`,
                    tw`sm:(mt-0 w-auto min-h-[235px] max-h-[235px])`,
                    tw`md:(min-h-[255px] max-h-[255px])`,
                    tw`landscape:(min-h-[235px] max-h-[235px])`,
                    language === "es" && tw`mt-8`,
                  ]}
                />
              </motion.section>
            </div>
          </>
        )}
        {/* HERO IMAGE DESKTOP */}
        {heroImage && (
          <div
            css={[
              tw`hidden row-start-1 row-end-4 self-center w-full relative z-10`,
              tw`lg:(block)`,
              tw`xl:(block)`,
            ]}
          >
            {/* {eventForefrontImage && (
              <div css={[tw`flex top-[-1.25rem] absolute left-[2.75rem] `]}>
                {eventForefrontImage && (
                  <Image
                    imageData={eventForefrontImage?.image}
                    //alt={"Sales Event"}
                    objectFit="contain"
                    transparentPreview
                    alt={salesEvent.eventForefrontImage?.alt}
                    css={[tw`h-[125px] md:(h-[150px]) -mt-5`]}
                  />
                )}
                <Image
                  imageData={salesEvent.eventImage?.image}
                  //alt={"Sales Event"}
                  objectFit="cover"
                  transparentPreview
                  alt={salesEvent.eventImage?.alt}
                  css={[tw`h-[115px] w-auto md:(h-[140px])`]}
                />
              </div>
            )} */}
            <motion.div
              animate={{ opacity: 1, x: -85, scale: 1 }}
              initial={{ opacity: 0, x: 0, scale: 0.95 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <Image
                imageData={heroImageData}
                //alt={heroImageAlt}
                objectFit="contain"
                css={[
                  tw`w-full pr-0`,
                  tw`lg:(mt-0 pt-52)`,
                  tw`xl:(ml-0 mt-0)`,
                  tw`2xl:(w-full max-w-[850px] pr-20 )`,
                  tw`desktop-hd:(pr-20)`,
                  language === "en" && tw`lg:(pt-6)`,
                ]}
                transparentPreview
              />
            </motion.div>
          </div>
        )}
        {/* DESKTOP COLORED / BLURRED BACKGROUND */}
        <motion.div
          css={[
            tw`relative flex backdrop-blur-[1px] z-0 backdrop-blur-sm`,
            language !== "es" &&
              `background:${
                salesEvent?.panelColor
                  ? `${salesEvent?.panelColor.hex}73`
                  : `${heroImage?.image?.asset.metadata.palette.dominant.background}D9`
              }`,
            language == "en" && tw`bg-black`,
            tw`sm:(w-full)`,
            tw`lg:(min-h-[700px] col-start-1 col-span-1 row-start-2 row-end-3 self-end mb-0 bg-opacity-60 h-[calc(50vh - 118px)])`,
            tw`xl:(bg-opacity-60 px-20 pr-24 h-[calc(100vh - 118px)]) max-h-[700px]`,
            tw`2xl:(max-h-[800px])`,
          ]}
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5, type: "spring" }}
        ></motion.div>
        <motion.div
          css={[
            //tw`row-start-2 col-span-full  pt-8 uppercase text-lg text-white text-center font-light bg-opacity-100 min-w-full`,
            tw`relative flex items-center uppercase text-black text-center font-light w-full  min-h-[450px] col-span-full`,
            tw`landscape:(min-h-[450px])`,
            tw`md:(min-h-[562px])`,
            tw`lg:(bg-transparent min-h-[700px] col-start-1 col-span-1 row-start-2 row-end-3 self-end justify-center mb-0 h-[700px])`,
            tw`xl:(px-8 pr-8 flex justify-end h-[calc(100vh - 118px)]) max-h-[700px]`,
            tw`2xl:(max-h-[800px])`,
          ]}
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5, type: "spring" }}
        >
          <div
            css={[
              tw`py-2 justify-center w-full`,
              `background:${
                salesEvent?.panelColor
                  ? `${salesEvent?.panelColor.hex}`
                  : `${heroImage?.image?.asset.metadata.palette.dominant.background}D9`
              } `,
              language === "en" ? tw`bg-gray-900 bg-opacity-100` : tw`bg-white`,
              //tw`md:(h-[48vh])`,
              tw`lg:(relative justify-end h-auto w-[500px] pt-8 bg-transparent)`,
              tw`2xl:(w-[650px])`,
              `@media all and (device-width: 430px) and (device-height: 739px) and (orientation:portrait) {
                top: -28px`,
            ]}
          >
            {/* <motion.div
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 10 }}
              transition={{ duration: 1, delay: 1.2, type: "spring" }}
            >
              <Image
                imageData={salesEvent.seriesEventImage?.image}
                //alt={"Sales Event"}
                objectFit="contain"
                transparentPreview
                alt={salesEvent.seriesEventImage?.alt}
                css={[
                  tw`hidden h-[115px] w-full`,
                  tw`lg:(block h-[85px] mb-2)`,
                  tw`2xl:(mb-4)`,
                ]}
              />
              <div
                css={[
                  tw`hidden text-2xl !normal-case font-semibold text-center`,
                  tw`lg:(hidden text-2xl text-center mb-4)`,
                  `color: ${salesEvent?.endDateColor?.hex};`,
                ]}
              >
                {_("Event Ends")} {formattedEndDate}
              </div>
            </motion.div> */}
            {/* SERIES NAME */}
            {seriesName && (
              <motion.div
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 10 }}
                transition={{ duration: 1, delay: 1.2, type: "spring" }}
                css={[
                  tw`z-40 w-full block text-center mt-5 mb-5`,
                  tw`landscape:(mt-5)`,
                  tw`md:(mt-10)`,
                  tw`lg:(relative mt-0)`,
                  tw`xl:(mt-0 mb-0)`,
                ]}
              >
                <span
                  css={[
                    tw`hidden font-book normal-case text-center text-3xl lg:(text-5xl) 2xl:(text-[3.5rem])`,
                    language == "es" &&
                      tw`inline text-black mb-3 lg:(mb-0 text-[2.5rem]) 2xl:(mb-6 text-[3.5rem])`,
                    language == "en" && tw`text-white`,
                  ]}
                >
                  {seriesName}&nbsp;
                </span>
                {!relatedSeriesPage?.page?.vehicle ? (
                  <span
                    css={[
                      tw`inline-block text-center text-black font-book text-3xl mb-0 lg:(text-[2.5rem])`,
                      language == "en" && tw`text-white`,
                    ]}
                  >
                    {vehicleYear}
                  </span>
                ) : vehicleYear > relatedSeriesPage.page?.vehicle?.year ? (
                  <span
                    css={[
                      tw`block text-center text-black font-semibold text-xl mb-0 lg:(mb-4 text-xl)`,
                      language == "en" && tw`text-white`,
                    ]}
                  >
                    <Link
                      to={`/${relatedSeriesPage.slug?.current}`}
                      animated
                      css={tw`p-2 mx-1 opacity-80`}
                    >
                      {relatedSeriesPage.page?.vehicle?.year}
                    </Link>
                    <span
                      css={[
                        tw`border-b-2 border-b-toyotaRed opacity-100 p-2 mx-1 padding-bottom[5px]`,
                      ]}
                    >
                      {vehicleYear}
                    </span>
                  </span>
                ) : (
                  <span
                    css={[
                      tw`block text-center font-semibold text-xl mb-0 lg:(mb-2 text-xl)`,
                      language == "en" && tw`text-white`,
                    ]}
                  >
                    <span
                      css={[
                        tw`border-b-2 border-b-toyotaRed mx-1 p-2 opacity-100 padding-bottom[5px]`,
                      ]}
                    >
                      {vehicleYear}
                    </span>
                    <Link
                      to={`/${relatedSeriesPage.slug?.current}`}
                      animated
                      css={tw`p-2 mx-1 opacity-80`}
                    >
                      {relatedSeriesPage.page?.vehicle?.year}
                    </Link>
                  </span>
                )}

                <span
                  css={[
                    tw`inline-block font-book text-black normal-case text-center text-3xl lg:(text-[2.5rem])`,
                    language == "es" && tw`hidden`,
                    language == "en" && tw`text-white`,
                  ]}
                >
                  &nbsp;{seriesName}&nbsp;
                </span>
              </motion.div>
            )}
            <div
              css={[
                tw`mt-2 flex flex-col`,
                tw`landscape:(mt-12)`,
                tw`md:(mt-4)`,
                tw`2xl:(mt-4)`,
              ]}
            >
              <FeaturedOffers
                offers={featuredOffers}
                textColor={
                  language === "es"
                    ? "#FFF"
                    : language === "en"
                    ? "#000"
                    : salesEvent?.salesEventActive
                    ? "#FFF"
                    : heroImage?.image.asset.metadata.palette.dominant
                        .background
                }
                salesEvent={language === "es" ? "#e10a1d" : "#fff"}
                chevronColor={language === "es" ? "#000" : "#fff"}
              />
            </div>
            <div css={[tw`w-full`, tw`sm:mt-4`, tw`2xl:mt-1`]}>
              {/* CTAs */}

              {/* START: logic added for Optimizely test 7 */}
              <motion.section
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 10 }}
                transition={{ duration: 1, delay: 1.2, type: "spring" }}
                css={[tw`block md:(hidden)`]}
              >
                <div css={[tw`flex flex-col gap-2 my-4 mx-4`]}>
                  <ButtonLink
                    to={`/dealers`}
                    css={[tw`uppercase font-semibold w-full`]}
                    animated
                    primary
                  >
                    {_("Find Your Dealer")}{" "}
                  </ButtonLink>
                  {orderedCTASforOptimizelyTest7 &&
                    orderedCTASforOptimizelyTest7?.map((cta: CTA) => {
                      const ctaLink = getCTALink(cta)
                      return (
                        <ButtonLink
                          {...(language === "es"
                            ? { secondary: true }
                            : { secondaryLight: true })}
                          to={ctaLink}
                          target={"_self"}
                          css={[tw`w-full whitespace-nowrap`]}
                          aria-label={cta?.title}
                          key={`${cta?.title}-variant`}
                          onClick={() => {
                            cta?.title == "View Inventory" ||
                            cta?.title == "Ver inventario"
                              ? trackTealEvent({
                                  tealium_event: "view_inventory_click",
                                  vehicle_model_truncated: seriesName,
                                  coupon_module_text: cta?.title,
                                  vehicle_year: vehicleYear,
                                  vehicle_model: seriesName,
                                  vehicle_segment_vehicle_page:
                                    reformattedCategory,
                                  link_href: getCTALinkValue(cta),
                                })
                              : null
                          }}
                          analytics-id={
                            cta?.title == "View Inventory" ||
                            cta?.title == "Ver inventario"
                              ? `inventory:hero:${seriesName}`
                              : null
                          }
                        >
                          {cta?.title}
                        </ButtonLink>
                      )
                    })}
                </div>
              </motion.section>
              {/* END: logic added for Optimizely test 7 */}

              {cta && (
                <motion.section
                  css={[
                    tw`hidden w-full justify-center items-center gap-4 px-6 mb-6`,
                    tw`md:(flex)`,
                    language == "es" && tw`flex-row-reverse`,
                    //tw`sm:(col-span-1 row-start-3 row-end-4 justify-center items-start px-0 my-0  absolute z-40)`,
                    tw`xl:(w-full col-span-1 row-start-3 gap-4 row-end-4 justify-center items-start px-0 my-0 relative)`,
                  ]}
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 10 }}
                  transition={{ duration: 1, delay: 1.4, type: "spring" }}
                >
                  {cta
                    ?.slice()
                    .reverse()
                    .map((cta: CTA) => {
                      const ctaLink = getCTALink(cta)
                      const isSecondaryLight =
                        cta.buttonType === "secondaryLight"
                      const isPrimary = cta.buttonType === "primary"
                      return (
                        <ButtonLink
                          {...(cta.buttonType !== "secondaryLight"
                            ? { [cta?.buttonType]: true }
                            : { secondaryLight: false })}
                          secondary={cta?.buttonType === "secondaryLight"}
                          secondaryLight={isPrimary}
                          to={ctaLink}
                          target={
                            cta?.linkType === "external" ? "_blank" : "_self"
                          }
                          css={[
                            tw`my-1 mx-2  w-auto whitespace-nowrap`,
                            language !== "es" &&
                              isSecondaryLight &&
                              tw`border-white text-white py-[.94rem] border`,
                            // isPrimary &&
                            // tw`lg:(text-red-400 hover:(text-white))`,
                            // css`
                            //   @media screen and (min-width: 1023px) {
                            //     &:before {
                            //       content: "";
                            //       z-index: -1;
                            //       ${tw`bg-white`};
                            //     }

                            //     &:after {
                            //       ${tw`bg-red-400`}
                            //     }

                            //     &:active {
                            //       &:after {
                            //         ${tw`bg-white`}
                            //       }
                            //     }
                            //   }
                            // `,
                          ]}
                          aria-label={cta?.title}
                          key={cta?.title}
                          onClick={() => {
                            cta?.title == "View Inventory" ||
                            cta?.title == "Ver inventario"
                              ? trackTealEvent({
                                  tealium_event: "view_inventory_click",
                                  vehicle_model_truncated: seriesName,
                                  coupon_module_text: cta?.title,
                                  vehicle_year: vehicleYear,
                                  vehicle_model: seriesName,
                                  vehicle_segment_vehicle_page:
                                    reformattedCategory,
                                  link_href: getCTALinkValue(cta),
                                })
                              : null
                          }}
                          analytics-id={
                            cta?.title == "View Inventory" ||
                            cta?.title == "Ver inventario"
                              ? `inventory:hero:${seriesName}`
                              : null
                          }
                        >
                          {cta?.title}
                        </ButtonLink>
                      )
                    })}
                </motion.section>
              )}
            </div>
            <motion.div
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 10 }}
              transition={{ duration: 1, delay: 1.2, type: "spring" }}
              css={[tw`hidden md:(block)`]}
            >
              <Link
                to={`/dealers`}
                css={[
                  tw`normal-case font-semibold text-base mt-0 mb-2 text-black`,
                  language == "en" && tw`text-white`,
                  tw`lg:(text-xl mt-2)`,
                  tw`xl:(mt-6)`,
                ]}
                animated
              >
                {_("Find Your Dealer")}{" "}
                <Icon.Chevron
                  direction="right"
                  color={language == "es" ? "#000" : "#fff"}
                  css={[tw`h-2.5 inline lg:(h-3)`]}
                />
              </Link>
            </motion.div>
          </div>
        </motion.div>

        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 10 }}
          transition={{ duration: 1, delay: 1.2, type: "spring" }}
          css={[
            tw`hidden absolute top-16 left-[57%]`,
            language == "es" && tw`left-[58%] top-28`,
            language == "en" && tw`left-[57%] top-auto bottom-16`,
            tw`lg:(block)`,
          ]}
        >
          <Image
            imageData={salesEvent?.seriesEventImage?.image}
            //alt={"Sales Event"}
            objectFit="contain"
            transparentPreview
            alt={salesEvent?.seriesEventImage?.alt}
            css={[
              tw`hidden lg:(block h-[220px] w-auto)`,
              language == "es" && tw`lg:(h-[100px])`,
              language == "en" && tw`lg:(h-[100px])`,
            ]}
          />
          <div
            css={[
              tw`hidden text-2xl !normal-case font-semibold text-center`,
              tw`lg:(hidden text-2xl text-center mb-4)`,
              `color: ${salesEvent?.endDateColor?.hex};`,
            ]}
          >
            {_("Event Ends")} {formattedEndDate}
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default SeriesSalesEventHero
