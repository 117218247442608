import React, { useContext, useEffect, useState } from "react"
import { OffersProps } from "./Offers.d"
import { Image } from "../../../atoms/Image"
import tw from "twin.macro"
import { OfferCard, OfferCardProps } from "../../../molecules/OfferCard"
import { JSX } from "@emotion/react/jsx-runtime"
import { SeriesPageContext } from "../../../../templates/series"
import Icon from "../../../atoms/Icon"
import { Link } from "../../../atoms/Link"
import { Offer } from "../../../../global"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { LanguageContext } from "../../../../contexts/Language"
import AliceCarousel from "react-alice-carousel"
import { toggleDisclaimersModal } from "../../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../../helpers"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import { OffersClient } from "../../../../clients/OffersClient"

const Offers: React.FC<OffersProps> = ({
  heading,
  featuredImage,
  _key,
  sectionSlug,
  margins,
  offerSortType,
}) => {
  const { language, _ } = useContext(LanguageContext)
  const { series, vehicle } = useContext(SeriesPageContext)
  const [offers, setOffers] = useState<Offer[]>(null)
  const [state, modalDispatch] = useContext(DisclaimersContext)

  useEffect(() => {
    const getOffersData = async () => {
      try {
        const offersData = await OffersClient.getOffersBySortOrder(
          offerSortType,
          series?.name,
          vehicle?.year
        )
        if (offersData.offers) {
          setOffers(offersData.offers)
        }
      } catch (error) {
        console.error("Error fetching offers data", error)
      }
    }
    getOffersData()
  }, [offerSortType])

  const cardVariants = {
    offscreen: {
      y: 300,
    },
    onscreen: (i: number) => ({
      y: 50,
      transition: {
        type: "spring",
        duration: 0.8,
        delay: i * 0.1,
      },
    }),
  }

  const fade = useAnimation()
  const { ref, inView } = useInView()

  const fadeVariants = {
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    },
    hidden: { opacity: 0 },
  }

  const responsive = {
    0: { items: 1 },
    711: { items: 2 },
    1017: { items: 3 },
    1480: { items: 4 },
  }

  const [items, setItems] = useState([])

  useEffect(() => {
    if (inView) {
      fade.start("visible")
    }
    setItems(
      offers &&
        offers?.map(
          (offer: JSX.IntrinsicAttributes & OfferCardProps, i: number) => (
            <motion.div
              initial="offscreen"
              custom={i}
              variants={cardVariants}
              whileInView="onscreen"
              viewport={{ once: true }}
              css={[tw`mb-6 flex gap-x-2 justify-center`, tw`md:(mb-16 px-12)`]}
            >
              <OfferCard
                key={offer?._id}
                offer={offer}
                cardType={offer?.cardLabel}
                cardLabel={
                  language === "es" ? offer?.cardLabelES : offer?.cardLabel
                }
                analyticsId={`offer details:offers:${i + 1}`}
              />
            </motion.div>
          )
        )
    )
  }, [fade, inView, offers])

  const renderPrevButton = ({ isDisabled }: { isDisabled: boolean }) => {
    return (
      <button
        css={[
          tw`absolute left-2 top-[50%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:bg-gunmetalHover focus-visible:(bg-gunmetalHover)`,
          tw`md:(-left-4 top-[50%])`,
          tw`lg:(-left-16 top-[50%])`,
          tw`2xl:(-left-20 top-[50%])`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButton = ({ isDisabled }: { isDisabled: boolean }) => {
    return (
      <button
        css={[
          tw`absolute right-2 top-[50%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:(bg-gunmetalHover) focus-visible:(bg-gunmetalHover)`,
          tw`md:(-right-4 top-[50%])`,
          tw`lg:(-right-16 top-[50%])`,
          tw`2xl:(-right-20 top-[50%])`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  return (
    <section
      css={[
        tw`relative bg-gray-50 pb-16`,
        tw`md:(bg-gradient-to-t from-gray-200 to-white)`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      key={_key}
      id={sectionSlug?.current}
      aria-label={_("Offers Section")}
    >
      <section css={[tw`md:(box-shadow[none])`]}>
        <motion.div
          ref={ref}
          animate={fade}
          variants={fadeVariants}
          whileInView="onscreen"
          viewport={{ once: true }}
          initial={{ opacity: 0 }}
          css={[tw`grid grid-cols-1 p-5 pb-0`]}
        >
          <Image
            imageData={featuredImage?.image}
            css={[tw`justify-self-center -mt-10 z-40 object-contain w-[815px]`]}
          />
          {heading && (
            <div
              css={[
                tw`justify-self-center text-3xl font-light tracking-widest`,
                tw`md:(text-5xl)`,
              ]}
            >
              {parseDisclaimerBlocks(heading, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </div>
          )}
          <div css={[tw`text-center`, tw`md:(mt-4)`]}>
            <span css={tw`font-semibold`}>{offers?.length}</span>
            <span>
              {offers?.length > 1 ? ` ${_("Offers")}` : ` ${_("Offer")}`}
            </span>
          </div>
        </motion.div>
      </section>

      <section
        css={[
          tw`flex flex-nowrap w-auto text-center justify-center`,
          tw`md:(mx-auto justify-center h-auto max-w-7xl px-10)`,
          tw`lg:(gap-8 max-w-7xl px-20)`,
          tw`xl:(gap-8 max-w-desktop)`,
          tw`2xl:(gap-8 max-w-desktop)`,
        ]}
      >
        {items && (
          <AliceCarousel
            items={items}
            disableDotsControls
            mouseTracking
            responsive={responsive}
            paddingLeft={25}
            paddingRight={25}
            controlsStrategy="alternate"
            renderPrevButton={renderPrevButton}
            renderNextButton={renderNextButton}
          />
        )}
      </section>
      <section
        css={[
          tw`px-6 pt-8 pb-4 flex justify-center items-center`,
          tw`md:(max-w-7xl mx-auto)`,
        ]}
      >
        <div css={[tw``]}>
          <Link
            animated
            animatedThin
            to="/offers"
            css={tw`font-bold focus-visible:(outline-gray)`}
          >
            {_("View All Toyota Offers")}
            <Icon.Chevron
              direction="right"
              color="red-400"
              css={[tw`h-3 inline ml-2`]}
            />
          </Link>
        </div>
      </section>
    </section>
  )
}

export default Offers
