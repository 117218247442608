import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
  ModelListProps,
  ModelListDetailProps,
  ModelListFilterProps,
  ModelListSelectorProps,
} from "./ModelList.d"
import tw from "twin.macro"
import { LanguageContext } from "../../../../contexts/Language"
import { InventoryClient } from "../../../../clients/InventoryClient"
import { LocationContext } from "../../../../contexts/Location"
import { Color, Model } from "../../../../global"
import {
  generateInventoryLink,
  getTealPowertrain,
  parseDisclaimerBlocks,
  removeDuplicates,
  selectedColorCheck,
} from "../../../../helpers"
import useDrag from "../../../../hooks/useDrag"
import { SeriesPageContext } from "../../../../templates/series"
import { Badge } from "../../../atoms/Badge"
import { Image } from "../../../atoms/Image"
import { Pill } from "../../../atoms/Pill"
import { TabGroup } from "../../../atoms/TabGroup"
import { ColorSelector } from "../../../molecules/ColorSelector"
import { LocalInventoryLinks } from "../../../molecules/LocalInventoryLinks"
import { ModelImageViewer } from "../../../molecules/ModelImageViewer"
import { MoreLikeThis } from "../../../molecules/MoreLikeThis"
import { PriceAndMPG } from "../../../molecules/PriceAndMPG"
import InteriorGallery from "./InteriorGallery"
import ModelListItem from "./ModelListItem"
import { useInView } from "react-intersection-observer"
import { DisclaimersContext } from "../../../../contexts/Disclaimers"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import { css } from "@emotion/react"
import {
  setDisclaimers,
  toggleDisclaimersModal,
} from "../../../../contexts/Disclaimers/actions"
import { useTealiumContext } from "../../../../contexts/Tealium"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import Icon from "../../../atoms/Icon"
import _ from "lodash"

const Filter: React.FC<ModelListFilterProps> = ({
  selected = false,
  children,
  ...remainingProps
}) => {
  return (
    <div css={[tw`inline-block relative`]}>
      <button
        css={[
          tw`inline-block py-3 px-5 rounded-lg`,
          selected && tw`font-semibold shadow-2 bg-white`,
        ]}
        {...remainingProps}
      >
        {children}
      </button>
    </div>
  )
}

const ModelListSelector: React.FC<ModelListSelectorProps> = ({
  models,
  selectedModelGroup,
  setSelectedModelGroup,
  seriesName,
  seriesTagline,
  heading,
  selectedFilter,
  setSelectedFilter,
  selectedColor,
}) => {
  const [filteredModels, setFilteredModels] = useState<Model[]>(models)
  const [state, dispatch] = useContext(DisclaimersContext)
  const [activeIndex, setActiveIndex] = useState(0)
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData, updateVehicleTealData } = useTealiumContext()
  const tealPowertrain = getTealPowertrain(models)

  const onlyHybrids = models.filter((model: Model[]) =>
    model.some(model => model?.model?.isHybridModel)
  )

  const allHybridOrAllElectric = models.every(
    group =>
      group.every(model => model.model.isHybridModel) ||
      group.every(model => model.model.isElectricModel)
  )

  useEffect(() => {
    if (selectedFilter === 0) {
      // 0 == "ALL"
      setFilteredModels(models)
      // Setting the active index, so alice carousel active slide styles are applied correctly
      if (selectedModelGroup) {
        const index = models?.findIndex(
          modelGroup => modelGroup.title === selectedModelGroup.title
        )
        setActiveIndex(index === -1 ? 0 : index)
      }
    } else {
      // 1 == "Hybrid"
      setFilteredModels(onlyHybrids)
      // Setting the active index, so alice carousel active slide styles are applied correctly
      const index = onlyHybrids?.findIndex(
        modelGroup => modelGroup.title === selectedModelGroup.title
      )
      setActiveIndex(index === -1 ? 0 : index)
    }
  }, [selectedFilter])

  const { handleMouseUp, handleMouseDown, handleMouseMove } = useDrag()

  const { ref, inView } = useInView()
  const languageObj = useContext(LanguageContext)

  const filterButtons = !allHybridOrAllElectric
    ? [languageObj._("All")].concat(
        onlyHybrids.length > 0 ? [languageObj._("Hybrid")] : []
      )
    : null

  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    859: { items: 3 },
    1023: { items: 3 },
    1100: { items: 4 },
    1440: { items: 5 },
    1600: { items: 6 },
    1919: { items: 7 },
  }

  const [items, setItems] = useState([])
  const { _ } = useContext(LanguageContext)
  useEffect(() => {
    setItems(
      filteredModels.map((modelGroup: any, i) => (
        <ModelListItem
          onSelect={() => {
            const extColorList = modelGroup[0]?.model?.colors
            const colorToUse = selectedColorCheck(
              selectedColor,
              extColorList?.length
            )
            trackTealEvent({
              features_series_prop: `model selector|${extColorList[colorToUse]?.exterior.title}|${modelGroup[0].model.name} `,
              vehicle_model: vehicleTealData?.vehicle_model || seriesName,
            })

            setSelectedModelGroup(
              models.filter(
                (e: Model) => e[0].model.id === modelGroup[0].model.id
              )[0]
            )
            setActiveIndex(i)
          }}
          active={modelGroup?.title === selectedModelGroup?.title}
          modelGroup={modelGroup}
          isElectricVehicle={modelGroup.some(x => x.model?.isElectricModel)}
          isHybridVehicle={modelGroup.some(x => x.model?.isHybridModel)}
          key={`model-list-item-${i}`}
          showConfigurations={selectedFilter === 0}
          analytics-id={`model selector:model list:${i + 1}`}
        />
      ))
    )
  }, [filteredModels, activeIndex])

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -left-6 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:bg-gray-400 focus-visible:(bg-gray-400)`,
          tw`lg:(-left-10)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="left" color="black" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -right-6 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-white rounded-full transition-all hover:(bg-gray-400) focus-visible:(bg-gray-400)`,
          tw`lg:(-right-10)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="right" color="black" css={[tw`h-5`]} />
      </button>
    )
  }
  const renderSlideInfo = ({ item, itemsCount }) => {
    return `${item}\\${itemsCount}`
  }

  return (
    <div ref={ref} css={[tw`bg-gray-100 pt-8 min-h-[342px] scrollbar-hide`]}>
      <>
        <div
          css={[
            tw`grid grid-cols-2 grid-rows-1 w-auto h-auto px-4 items-center`,
            tw`lg:(grid-cols-6 px-16)`,
          ]}
        >
          <div
            css={[tw`col-span-12 flex flex-col items-center justify-center`]}
          >
            {seriesName && (
              <h1
                css={[
                  tw`text-4xl tracking-widest font-light leading-snug`,
                  tw`md:(text-5xl tracking-widest font-light leading-snug pb-5)`,
                ]}
              >
                {heading
                  ? parseDisclaimerBlocks(heading, selection =>
                      dispatch(toggleDisclaimersModal(selection))
                    )
                  : parseDisclaimerBlocks(
                      seriesName + " " + languageObj._("Models"),
                      selection => dispatch(toggleDisclaimersModal(selection))
                    )}
              </h1>
            )}
            <p
              css={[
                tw`text-base font-book md:(text-lg text-center max-w-7xl mb-8)`,
              ]}
            >
              {!seriesTagline
                ? languageObj._("Find the perfect match for your lifestyle")
                : parseDisclaimerBlocks(seriesTagline, selection =>
                    dispatch(toggleDisclaimersModal(selection))
                  )}
            </p>
          </div>
          <div
            css={[
              tw`col-start-1 col-end-3 text-center mb-8 mt-4`,
              tw`lg:(col-span-2 col-start-3 items-center justify-center)`,
            ]}
          >
            {filterButtons &&
              filterButtons.map((filter, i) => {
                const selected = i === selectedFilter
                return (
                  <>
                    <Filter
                      selected={selected}
                      onClick={() => {
                        setSelectedFilter(i),
                          trackTealEvent({
                            features_series_prop: `powertrain:${filter}`,
                            vehicle_powertrain: tealPowertrain,
                          })
                      }}
                      key={`filter-${i}`}
                      css={tw`capitalize`}
                      analytics-id={`powertrain selector:${i + 1}`}
                    >
                      {filter}
                      <div
                        css={[
                          tw`hidden absolute -right-1.5 -top-1.5 px-2 py-0.5 rounded-full bg-toyotaRed text-gray-50 text-sm`,
                          selected && tw`block`,
                        ]}
                      >
                        {filteredModels.length}
                      </div>
                    </Filter>
                  </>
                )
              })}
          </div>
        </div>
        <div
          onMouseUp={handleMouseUp}
          // onMouseDown={handleMouseDown}
          // onMouseMove={handleMouseMove}
          // onMouseLeave={handleMouseMove}
        >
          <div css={[tw`overflow-hidden h-[152px] px-8 md:(px-16)`]}>
            <div
              css={[tw`flex flex-nowrap w-auto text-center justify-center`]}
              className="modelListItems"
            >
              <AliceCarousel
                mouseTracking
                items={items}
                disableDotsControls
                responsive={responsive}
                paddingLeft={18}
                paddingRight={18}
                controlsStrategy="default"
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
                activeIndex={activeIndex}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

const ModelListDetail: React.FC<ModelListDetailProps> = ({
  selectedFilter,
  modelGroup,
  upsell,
  downsell,
  selectedColor,
  setSelectedColor,
  pageDisclaimers = [],
  disclaimer,
}: ModelListDetailProps) => {
  const [inventoryCount, setInventoryCount] = useState<number | "...">(0)
  const [filteredModelGroup, setFilteredModelGroup] = useState(modelGroup)
  const [state, dispatch] = useContext(DisclaimersContext)
  const { trackTealEvent } = useTealiumEvent()
  const { updateVehicleTealData, vehicleTealData } = useTealiumContext()
  const { series, vehicle } = useContext(SeriesPageContext)
  const [selectedModel, setSelectedModel] = useState<Model>(
    modelGroup[0]?.model
  )
  const hybridModels = modelGroup.filter(
    (model: Model) => model?.model?.isHybridModel === true
  )
  // For Tealium event
  const [clicked, setClicked] = useState(false)

  const exteriorColorList: Color[] = selectedModel?.colors?.map(
    (color: any) => {
      return {
        name: color?.exterior?.title,
        code: color?.exterior?.code,
        hex: color?.exterior?.colors?.map((color: any) => color?.hex),
      }
    }
  )

  const exteriorImages = selectedModel?.colors?.map((color: any) => {
    return color?.exteriorImages
  })

  const [{ city, dealers }] = useContext(LocationContext)

  const languageObj = useContext(LanguageContext)

  useEffect(() => {
    setFilteredModelGroup(modelGroup)
    if (selectedFilter === 0) {
      setFilteredModelGroup(modelGroup)
      setSelectedModel(modelGroup[0].model)

      const modelDisclaimers =
        modelGroup[0].model.disclaimers.highlightedFeatures
      const newDisclaimers = _.unionBy(
        modelDisclaimers,
        state.disclaimers,
        "code"
      )
      dispatch(setDisclaimers(newDisclaimers))
    } else {
      setFilteredModelGroup(hybridModels.length > 0 ? hybridModels : modelGroup)
      if (hybridModels.length > 0) {
        setSelectedModel(hybridModels[0].model)
        const modelDisclaimers =
          hybridModels[0].model.disclaimers.highlightedFeatures

        const newDisclaimers = _.unionBy(
          modelDisclaimers,
          state.disclaimers,
          "code"
        )
        dispatch(setDisclaimers(newDisclaimers))
      } else {
        // if you are currently on a group that doesn't have a hybrid model
        // set the filtered model group to the first model group that has a hybrid model
        setSelectedModel(null)
      }
    }
  }, [modelGroup])

  useEffect(() => {
    if (selectedFilter === 0) {
      setFilteredModelGroup(modelGroup)
      setSelectedModel(modelGroup[0]?.model)
    } else {
      setFilteredModelGroup(
        modelGroup.filter((model: Model) => {
          return model?.model?.isHybridModel
        })
      )
      if (hybridModels.length > 0) {
        setSelectedModel(hybridModels[0].model)
      } else {
        // if you are currently on a group that doesn't have a hybrid model
        // set the filtered model group to the first model group that has a hybrid model
        setSelectedModel(null)
      }
    }
  }, [selectedFilter])

  // Update tealium context for model and trim whenever the a trim is rendered or updated
  // Update tealium context for exterior color whenever the color pallette is rendered or changes. Defaults to first color if color array is not long enough.
  useEffect(() => {
    if (!selectedModel) return
    updateVehicleTealData({
      trim: selectedModel?.name,
      exterior_color:
        exteriorColorList[selectedColor]?.name || exteriorColorList[0]?.name,
    })
  }, [selectedModel, selectedColor])

  const dealerIds = useMemo<string>(
    () => dealers?.map(dealer => dealer?.DealerCode).join(","),
    [dealers]
  )

  useEffect(() => {
    if (!selectedModel) return
    if (!dealerIds) return

    const getCount = async () => {
      try {
        setInventoryCount("...")
        const { count } = await InventoryClient.getCount(
          selectedModel?.series?.slug,
          {
            dealer: dealerIds,
            modelNumber: selectedModel?.code,
            year: vehicle.year.toString(),
          }
        )
        setInventoryCount(count)
      } catch (error) {
        setInventoryCount(error)
      }
    }
    getCount()
  }, [selectedModel, dealers])

  const responsive = {
    0: { items: 1 },
    614: { items: 2 },
    767: { items: 1 },
    1023: { items: 2 },
    1280: { items: 3 },
  }

  const [items, setItems] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (clicked) {
      const colorToUse = selectedColorCheck(
        selectedColor,
        selectedModel?.colors?.length
      )

      trackTealEvent({
        features_series_prop: `configuration selector|${selectedModel.colors[colorToUse].exterior.title}|${selectedModel.name}`,
        vehicle_model: vehicleTealData.vehicle_model || series,
      })
      updateVehicleTealData({ vehicle_config: selectedModel?.name })
      setClicked(false)
    }
  }, [clicked])

  const filteredAcc = selectedModel?.highlightedFeatures
    .filter(
      (feature: { language: string; index: number }) =>
        feature?.language === languageObj.language
    )
    .map(({ title }: any, i: any) => title)

  const accessories = removeDuplicates(filteredAcc)

  const hybridText = languageObj._("Hybrid")
  const electricText = languageObj._("Electric")
  useEffect(() => {
    setItems(
      Array.isArray(filteredModelGroup) &&
        filteredModelGroup.map((configuration: Model, i: number) => {
          return (
            <div
              className="configuration"
              css={[
                tw`hidden cursor-pointer relative mx-4 pb-4 mt-2`,
                selectedModel?.id === configuration.model.id &&
                  tw`rounded-xl bg-gray-50 p-4 mt-2 border-gray-700 border-dashed border !outline-none`,
                tw`focus-visible:(border-gray-700 border-dashed border outline-none)`,
                tw`md:(rounded-3xl text-center p-4 mt-2)`,
                filteredModelGroup.length > 1 && tw`block`,
              ]}
              tabIndex={0}
              onClick={() => {
                // This is to trigger Tealium event b/c it doesn't fire properly within this useEffect
                setClicked(true)
                const model = modelGroup.filter(
                  (model: any) => model.model.id === configuration.model.id
                )
                setSelectedModel(model[0].model)
              }}
              onKeyDown={e => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault() // Prevent default action for space key
                  handleSelection(configuration.model)
                }
              }}
              ref={el => {
                if (el && selectedModel?.id === configuration.model.id) {
                  el.scrollIntoView({ behavior: "smooth", block: "nearest" })
                }
              }}
              analytics-id={`configuration selector:model list:${i + 1}`}
            >
              {(configuration.model?.isElectricModel ||
                configuration.model?.isHybridModel) && (
                <div css={[tw`flex justify-center overflow-visible`]}>
                  <Badge
                    color={
                      configuration.model?.isHybridModel ? "green" : "blue"
                    }
                    css={[tw`absolute -top-2 text-xs`]}
                  >
                    {configuration.model?.isHybridModel
                      ? hybridText
                      : electricText}
                  </Badge>
                </div>
              )}
              <Image
                imageData={configuration.model.jellybean} //TODO we need to set the image based on the featured color selected in the CMS
                css={[tw`w-48 mx-auto`, tw`lg:(w-40)`, tw`2xl:(w-48)`]}
                draggable={false}
              />
              <div css={[tw`block px-2`]}>{configuration.model.name}</div>
            </div>
          )
        })
    )

    slideTo(filteredModelGroup.findIndex(x => x.model.id === selectedModel?.id))
  }, [filteredModelGroup, selectedModel])

  const handleSelection = model => {
    setClicked(true)
    const selectedModel = modelGroup.find((m: any) => m.model.id === model.id)
    setSelectedModel(selectedModel?.model)
  }

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -left-10 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:bg-gunmetalHover focus-visible:(bg-gunmetalHover)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="left" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const renderNextButton = ({ isDisabled }) => {
    return (
      <button
        css={[
          tw`absolute -right-10 top-[45%] -translate-y-1/2 z-50 flex justify-center items-center cursor-pointer w-16 h-10 bg-gunmetal rounded-full transition-all hover:(bg-gunmetalHover) focus-visible:(bg-gunmetalHover)`,
          isDisabled ? tw`hidden` : tw`flex`,
        ]}
      >
        <Icon.Chevron direction="right" color="white" css={[tw`h-5`]} />
      </button>
    )
  }

  const slideTo = (index: number) => setActiveIndex(index)
  return (
    <>
      {selectedModel == null ? (
        <div css={[tw`flex justify-center items-center h-64`]}>
          {languageObj._("Select a hybrid model above")}
        </div>
      ) : (
        <div
          css={[
            tw`grid grid-cols-2`,
            tw`md:(grid-cols-4 gap-4)`,
            tw`lg:(grid-cols-5 gap-4 grid-flow-row-dense)`,
          ]}
        >
          <div
            css={[
              tw`row-start-1 col-span-2 px-4`,
              tw`md:(ml-6)`,
              tw`lg:(px-0)`,
            ]}
          >
            {selectedModel?.series?.name && selectedModel?.name && (
              <h3
                css={[
                  tw`text-3xl font-semibold uppercase text-center`,
                  tw`md:(text-5xl mb-6 text-left)`,
                ]}
              >
                {selectedModel?.series?.name}{" "}
                <span css={[tw`block text-2xl font-book md:(text-4xl)`]}>
                  {selectedModel?.name}
                </span>
              </h3>
            )}
            <p css={[tw`text-2xl font-light text-center md:(text-left)`]}>
              {languageObj._("Leave a lasting impression")}
            </p>
            <div css={[tw`row-start-3 col-span-2 mt-8`]}>
              <PriceAndMPG
                msrp={selectedModel?.costs?.baseMSRP}
                mpgHighway={selectedModel?.epa?.mpgHighway}
                mpgCity={selectedModel?.epa?.mpgCity}
                range={selectedModel?.epa?.range}
                inline
                seriesName={selectedModel?.series?.name}
                seriesYear={vehicle?.year?.toString()}
              />
            </div>
          </div>

          <div
            css={[
              tw`row-start-3 col-span-2 px-8`,
              tw`md:(col-span-2 row-start-2 px-8 ml-6)`,
              tw`lg:(col-span-2 px-0)`,
            ]}
          >
            <LocalInventoryLinks
              city={city}
              dealerCount={dealers?.length}
              share
              analyticsId="model list:"
              inventoryCount={inventoryCount}
              inventoryLink={generateInventoryLink({
                series: series.slug,
                modelNumber: selectedModel?.code,
                year: [vehicle.year.toString()],
                ...(selectedModel?.accessoryCodes && {
                  accessoryModels: [selectedModel?.accessoryCodes],
                }),
              })}
              name={`${selectedModel?.series?.name || ""} ${
                selectedModel?.name || ""
              }`}
              series={selectedModel?.series?.slug}
            />
          </div>
          {accessories && (
            <div
              css={[
                tw`row-start-4 col-span-2 px-8 mt-16`,
                tw`md:(row-start-3 col-span-2 ml-6 mt-0)`,
                tw`lg:(px-0)`,
              ]}
            >
              <ul css={[tw`list-disc pl-6`]}>
                {accessories.map((title: any, i: any) => (
                  <li
                    key={`feature-${i}`}
                    css={[tw`font-semibold mb-3 text-sm`]}
                  >
                    {parseDisclaimerBlocks(title, selection =>
                      dispatch(toggleDisclaimersModal(selection))
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div
            css={[
              tw`row-start-2 col-start-1 col-end-3 mt-4`,
              tw`md:(col-span-3 col-start-3 col-end-6 row-start-1 mt-0 row-end-6 min-h-[54rem])`,
            ]}
          >
            <div css={[tw`text-center mb-2`]}>
              <h3
                css={[tw`text-base text-red-400 font-semibold tracking-widest`]}
              >
                {filteredModelGroup.length > 1 &&
                  languageObj._("Choose Your Configuration")}
              </h3>
              <span css={[tw`text-xs`]}>
                {filteredModelGroup.length > 1 &&
                  `${filteredModelGroup.length} ${languageObj._(
                    "Configurations"
                  )}`}
              </span>
            </div>
            <div css={[tw`text-center flex pt-4 mx-12 max-h-[200px]`]}>
              <AliceCarousel
                mouseTracking
                items={items}
                disableDotsControls
                paddingLeft={10}
                paddingRight={10}
                responsive={responsive}
                controlsStrategy="alternate"
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
                activeIndex={activeIndex}
              />
            </div>
            <TabGroup>
              {({ activeTab, changeTab }) => (
                <>
                  <div css={[tw`text-center mt-12 mb-6`]}>
                    <Pill
                      selected={activeTab === 0}
                      onClick={() => changeTab(0)}
                      alignment={"left"}
                    >
                      {languageObj._("Exterior")}
                    </Pill>
                    <Pill
                      selected={activeTab === 1}
                      onClick={() => changeTab(1)}
                      alignment={"left"}
                    >
                      {languageObj._("Interior")}
                    </Pill>
                  </div>
                  <div
                    css={[
                      tw`hidden mb-6 md:(min-h-[540px])`,
                      activeTab === 0 && tw`block`,
                    ]}
                  >
                    <ModelImageViewer
                      images={exteriorImages[selectedColor]}
                      disclaimer={disclaimer}
                    />
                    <ColorSelector
                      colorList={exteriorColorList}
                      onChange={selectedSwatch =>
                        setSelectedColor(selectedSwatch)
                      }
                      analyticsId={`exterior color selector:model list:`}
                    />
                  </div>
                  <div
                    css={[
                      tw`hidden h-96 `,
                      tw`md:(min-h-[360px] h-[23rem] mb-20)`,
                      activeTab === 1 && tw`block mx-4`,
                      tw`md:(mx-10)`,
                    ]}
                  >
                    <InteriorGallery model={selectedModel} />
                  </div>
                </>
              )}
            </TabGroup>
            <div
              css={[
                tw`grid overflow-hidden grid-cols-12 grid-rows-1 gap-1 grid-flow-row w-auto h-auto`,
              ]}
            >
              {upsell && downsell && (
                <MoreLikeThis upsell={upsell} downsell={downsell} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const ModelList: React.FC<ModelListProps> = ({
  _key,
  sectionSlug,
  margins,
  heading,
}) => {
  const { models, series, vehicle, selectedFilter, setSelectedFilter } =
    useContext(SeriesPageContext)
  const languageObj = useContext(LanguageContext)
  const onlyHybrids = models.filter((model: Model[]) =>
    model.some(model => model.model?.isHybridModel)
  )
  const [selectedModelGroup, setSelectedModelGroup] = useState(
    selectedFilter === 0 ? models[0] : onlyHybrids[0]
  )
  const [selectedColor, setSelectedColor] = useState<number>(0)
  let seriesTagline =
    languageObj.language === "es"
      ? vehicle?.seriesTaglineES
      : vehicle?.seriesTagline

  return (
    <section
      css={[
        tw`relative py-0`,
        `margin: ${margins?.top}px 0 ${margins?.bottom}px !important;`,
      ]}
      key={_key}
      id={sectionSlug?.current}
      aria-label={languageObj._("Model List Section")}
      analytics-id="model-section"
    >
      {models && (
        <ModelListSelector
          models={models}
          selectedModelGroup={selectedModelGroup}
          setSelectedModelGroup={setSelectedModelGroup}
          seriesName={series?.name}
          seriesTagline={seriesTagline}
          heading={heading}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          selectedColor={selectedColor}
        />
      )}
      {selectedModelGroup && (
        <div css={[tw`container mx-auto pt-16 block`]}>
          <ModelListDetail
            modelGroup={selectedModelGroup}
            upsell={vehicle?.upsell}
            downsell={vehicle?.downsell}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            disclaimer={selectedModelGroup.disclaimer}
          />
        </div>
      )}
    </section>
  )
}

export default ModelList
