import React, { useContext, useEffect } from "react"
import { HeroProps } from "./Hero.d"
import tw from "twin.macro"
import { ButtonLink } from "../../../atoms/Button"
import { Image } from "../../../atoms/Image"
import { CTA, Offer } from "../../../../global"
import { SeriesPageContext } from "../../../../templates/series"
import { motion } from "framer-motion"
import useTealiumEvent from "../../../../hooks/Tealium/useTealiumEvent"
import { Link } from "../../../atoms/Link"
import Icon from "../../../atoms/Icon"
import { LanguageContext } from "../../../../contexts/Language"
import {
  generateInventoryLink,
  getCTALinkValue,
  reformatCategory,
} from "../../../../helpers"
import FeaturedOffers from "./FeaturedOffers"
import { OffersClient } from "../../../../clients/OffersClient"

/**
 *
 * @author Cody & Scott
 * @summary
 *
 */

const UpdatedHero: React.FC<HeroProps> = ({
  model,
  offerType,
  secondaryOfferType,
  backgroundImages,
  heroImage,
  cta,
  _key,
  sectionSlug,
  relatedSeriesPage,
  panelColor,
}) => {
  const { vehicle } = useContext(SeriesPageContext)
  const { _, language } = useContext(LanguageContext)
  const userLocation = "US"

  const { trackTealEvent } = useTealiumEvent()

  /* Pull in background image that matches user geo location, if none match use 'all' */
  const bgImage = backgroundImages?.find(
    location =>
      location?.geolocation === userLocation || location?.geolocation === "all"
  )?.image

  // Image data and alts
  const bgImageData = bgImage?.image
  const heroImageData = heroImage?.image

  // Vehicle data
  const seriesName = model?.series?.name
  const vehicleYear = vehicle?.year

  const [featuredOffers, setFeaturedOffers] = React.useState<Offer[]>([])
  useEffect(() => {
    const getOffersData = async () => {
      try {
        const offersData = await OffersClient.getManyOffers({
          model: JSON.stringify(model?.name),
          limit: 2,
          series: JSON.stringify(seriesName),
          type: JSON.stringify([offerType, secondaryOfferType]),
          year: model?.year,
        })
        if (offersData.offers) {
          setFeaturedOffers(prevOffers => [...prevOffers, ...offersData.offers])
        }
      } catch (error) {
        console.error("Error fetching offers data", error)
      }
    }
    getOffersData()
  }, [vehicle, model])
  const iPadPortrait = `
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    padding-top: 7rem;
  }`
  const iPadLandscape = `
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {
    padding-top: 7rem;
  }`
  const iPadPortraitBackground = `
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    height: calc(80vh - 100px)
  }`
  const iPadPortraitImage = `
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    min-height: calc(320px)
  }`

  const reformattedCategory = reformatCategory(vehicle?.series?.category)

  const getCTALink = (cta: CTA) => {
    let link = getCTALinkValue(cta)
    if (link?.includes("inventory")) {
      link = generateInventoryLink({ series: vehicle?.series?.slug })
    }

    return link
  }

  // START: Logic for Optimizely Test 7
  const orderedCTASforOptimizelyTest7 = cta?.sort((a, b) => {
    const titleA = a.title.toLowerCase()
    const titleB = b.title.toLowerCase()

    if (titleA === "view inventory" || titleA === "view inventario") {
      return -1
    } else {
      return 1
    }
  })
  // END: Logic for Optimizely Test 7

  return (
    <section
      css={[
        tw`relative flex z-50`,
        tw`lg:(min-h-[calc(50vh - 118px)])`,
        tw`xl:(min-h-[700px])`,
        tw`2xl:(min-h-[800px])`,
      ]}
      key={_key}
      id={sectionSlug?.current}
      aria-label={`${vehicleYear} ${seriesName}` + " Hero Section"}
    >
      {/* BACKGROUND IMAGE */}
      {bgImageData && (
        <div
          css={[
            tw`w-full h-full absolute top-0 overflow-hidden`,
            tw`lg:(col-span-full self-start w-full absolute auto-rows-min top-0)`,
            iPadPortraitBackground,
          ]}
        >
          <Image
            imageData={bgImageData}
            css={[
              tw`object-cover w-full h-[40vh]`,
              tw`sm:(object-cover w-full h-[70vh])`,
              tw`lg:(min-h-[700px] top-0 h-full)`,
              tw`xl:(h-[calc(100vh - 118px)])`,
              tw`2xl:(h-full)`,
              "z-index: 0;",
            ]}
          />
        </div>
      )}

      <div
        css={[tw`relative w-full`, tw`lg:(mt-0 grid grid-rows-1 grid-cols-2)`]}
      >
        {heroImage && (
          <>
            {/* HERO IMAGE MOBILE */}
            <motion.section
              animate={{ opacity: 1, x: 0, scale: 1 }}
              initial={{ opacity: 0, x: 0, scale: 0.95 }}
              transition={{ duration: 1, delay: 1 }}
              css={[
                tw` w-full h-[40vh] z-20 flex items-center justify-center`,
                tw`sm:(h-[70vh])`,
                tw`lg:(hidden)`,
              ]}
            >
              <Image
                imageData={heroImageData}
                objectFit="cover"
                css={[
                  tw`w-auto mt-8 min-h-[175px]`,
                  tw`sm:(mt-8 w-auto min-h-[235px] max-h-[235px])`,
                  tw`md:(min-h-[235px] max-h-[235px])`,
                  tw`lg:(min-h-[320px])`,
                  iPadPortraitImage,
                ]}
              />
            </motion.section>
          </>
        )}
        {/* HERO IMAGE DESKTOP */}
        {heroImage && (
          <motion.section
            animate={{ opacity: 1, x: -110, scale: 1 }}
            initial={{ opacity: 0, x: 0, scale: 0.95 }}
            transition={{ duration: 1, delay: 1 }}
            css={[
              tw`hidden col-start-2 self-center w-full relative z-10`,
              tw`lg:(block)`,
            ]}
          >
            <Image
              imageData={heroImageData}
              objectFit="contain"
              css={[
                tw`w-full pr-0`,
                tw`lg:(max-w-[700px])`,
                tw`2xl:(w-full max-w-[900px] pr-20)`,
                tw`desktop-hd:(pr-40)`,
              ]}
              transparentPreview
            />
          </motion.section>
        )}
        {/* DESKTOP COLORED / BLURRED BACKGROUND */}
        <motion.div
          css={[
            tw`relative flex backdrop-blur-[1px] z-0`,
            `background:${
              panelColor
                ? `${panelColor.hex}D9`
                : `${heroImage?.image?.asset.metadata.palette.dominant.background}D9`
            }`,
            tw`sm:(w-full)`,
            tw`lg:(col-start-1 col-span-1 self-end mb-0 bg-opacity-80 h-full row-span-full)`,
            tw`xl:(bg-opacity-75 px-20 pr-24)`,
          ]}
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5, type: "spring" }}
        ></motion.div>
        <motion.div
          css={[
            tw`relative flex items-center uppercase text-white text-center font-light bg-transparent`,
            `background:${
              panelColor
                ? `${panelColor.hex}D9`
                : `${heroImage?.image?.asset.metadata.palette.dominant.background}D9`
            }`,
            tw`sm:(w-full)`,
            tw`lg:(bg-transparent min-h-[700px] col-start-1 col-span-1 self-end justify-center mb-0 row-span-full h-full)`,
            tw`xl:(px-20 pr-24 flex justify-end)`,
          ]}
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5, type: "spring" }}
        >
          <div
            css={[
              tw`py-8 justify-center w-full`,
              tw`lg:(justify-end w-[500px])`,
            ]}
          >
            {/* SERIES NAME */}
            {seriesName && (
              <motion.div
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 10 }}
                transition={{ duration: 1, delay: 1.2, type: "spring" }}
              >
                <span
                  css={[
                    tw`hidden font-book normal-case text-center text-6xl lg:(text-7xl)`,
                    language == "es" && tw`block mb-6`,
                  ]}
                >
                  {seriesName}
                </span>
                {!relatedSeriesPage?.page?.vehicle ? (
                  <span
                    css={[
                      tw`block text-center font-light text-3xl mb-0 lg:(mb-6 text-5xl)`,
                    ]}
                  >
                    {vehicleYear}
                  </span>
                ) : vehicleYear > relatedSeriesPage.page?.vehicle?.year ? (
                  <span
                    css={[
                      tw`block text-center font-semibold text-xl mb-0 lg:(mb-6 text-2xl)`,
                    ]}
                  >
                    <Link
                      to={`/${relatedSeriesPage.slug?.current}`}
                      animated
                      css={tw`p-2 mx-1 opacity-80`}
                    >
                      {relatedSeriesPage.page?.vehicle?.year}
                    </Link>
                    <span
                      css={[
                        tw`border-b-2 border-b-toyotaRed opacity-100 p-2 mx-1 padding-bottom[5px]`,
                      ]}
                    >
                      {vehicleYear}
                    </span>
                  </span>
                ) : (
                  <span
                    css={[
                      tw`block text-center font-semibold text-xl mb-0 lg:(mb-6 text-2xl)`,
                    ]}
                  >
                    <span
                      css={[
                        tw`border-b-2 border-b-toyotaRed mx-1 p-2 opacity-100 padding-bottom[5px]`,
                      ]}
                    >
                      {vehicleYear}
                    </span>
                    <Link
                      to={`/${relatedSeriesPage.slug?.current}`}
                      animated
                      css={tw`p-2 mx-1 opacity-80`}
                    >
                      {relatedSeriesPage.page?.vehicle?.year}
                    </Link>
                  </span>
                )}

                <span
                  css={[
                    tw`block font-book normal-case text-center text-6xl lg:(text-5xl) xl:(text-[2.5rem]) 2xl:(text-[3.5rem])`,
                    language == "es" && tw`hidden`,
                  ]}
                >
                  {seriesName}
                </span>
              </motion.div>
            )}
            <div css={[tw`mt-6 sm:(mt-12 flex flex-col)`]}>
              <FeaturedOffers
                offers={featuredOffers}
                textColor={
                  panelColor
                    ? panelColor.hex
                    : heroImage?.image.asset.metadata.palette.dominant
                        .background
                }
              />
            </div>
            <div css={[tw`mt-4`]}>
              {/* CTAs */}
              {/* START: logic added for Optimizely test 7 */}
              <motion.section
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 10 }}
                transition={{ duration: 1, delay: 1.2, type: "spring" }}
                css={[tw`md:(hidden)`]}
              >
                <div css={[tw`flex flex-col gap-2 my-4 mx-4`]}>
                  <ButtonLink
                    to={`/dealers`}
                    css={[
                      tw`uppercase font-semibold w-full md:(!font-semibold)`,
                    ]}
                    animated
                    primary
                  >
                    {_("Find Your Dealer")}{" "}
                  </ButtonLink>
                  {orderedCTASforOptimizelyTest7 &&
                    orderedCTASforOptimizelyTest7?.map((cta: CTA) => {
                      const ctaLink = getCTALink(cta)
                      return (
                        <ButtonLink
                          secondaryLight
                          to={ctaLink}
                          target={"_self"}
                          css={[tw`w-full whitespace-nowrap`]}
                          aria-label={cta?.title}
                          key={`${cta?.title}-variant`}
                          onClick={() => {
                            cta?.title == "View Inventory" ||
                            cta?.title == "Ver inventario"
                              ? trackTealEvent({
                                  tealium_event: "view_inventory_click",
                                  vehicle_model_truncated: seriesName,
                                  coupon_module_text: cta?.title,
                                  vehicle_year: vehicleYear,
                                  vehicle_model: seriesName,
                                  vehicle_segment_vehicle_page:
                                    reformattedCategory,
                                  link_href: getCTALinkValue(cta),
                                })
                              : null
                          }}
                          analytics-id={
                            cta?.title == "View Inventory" ||
                            cta?.title == "Ver inventario"
                              ? `inventory:hero:${seriesName}`
                              : null
                          }
                        >
                          {cta?.title}
                        </ButtonLink>
                      )
                    })}
                </div>
              </motion.section>
              {/* END: logic added for Optimizely test 7 */}
              {cta && (
                <motion.section
                  css={[
                    tw`hidden items-center gap-4 px-6`,
                    tw`sm:(flex justify-center items-center gap-4 px-6 my-10)`,
                    tw`lg:(justify-center items-start px-0 my-0)`,
                  ]}
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 10 }}
                  transition={{ duration: 1, delay: 1.4, type: "spring" }}
                >
                  {cta?.map((cta: CTA) => {
                    const ctaLink = getCTALink(cta)
                    return (
                      <ButtonLink
                        {...{ [cta?.buttonType]: true }}
                        to={getCTALinkValue(cta)}
                        target={
                          cta?.linkType === "external" ? "_blank" : "_self"
                        }
                        css={[
                          tw`my-1 w-full whitespace-nowrap`,
                          tw`sm:my-0`,
                          tw`md:w-auto`,
                        ]}
                        aria-label={cta?.title}
                        key={cta?.title}
                        onClick={() => {
                          cta?.title == "View Inventory" ||
                          cta?.title == "Ver inventario"
                            ? trackTealEvent({
                                tealium_event: "view_inventory_click",
                                vehicle_model_truncated: seriesName,
                                coupon_module_text: cta?.title,
                                vehicle_year: vehicleYear,
                                vehicle_model: seriesName,
                                vehicle_segment_vehicle_page:
                                  reformattedCategory,
                                link_href: getCTALinkValue(cta),
                              })
                            : null
                        }}
                        analytics-id={
                          cta?.title == "View Inventory" ||
                          cta?.title == "Ver inventario"
                            ? `inventory:hero:`
                            : null
                        }
                      >
                        {cta?.title}
                      </ButtonLink>
                    )
                  })}
                </motion.section>
              )}
            </div>
            <motion.div
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 10 }}
              transition={{ duration: 1, delay: 1.2, type: "spring" }}
              css={[tw`hidden md:(block)`]}
            >
              <Link
                to={`/dealers`}
                css={[
                  tw`normal-case text-base mt-2 mb-2 text-white`,
                  tw`lg:(text-xl font-semibold mt-12)`,
                ]}
                animated
              >
                {_("Find Your Dealer")}{" "}
                <Icon.Chevron
                  direction="right"
                  color={"white"}
                  css={[tw`h-2.5 inline lg:(h-3)`]}
                />
              </Link>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default UpdatedHero
